import React, { useEffect, useState, TouchEvent, useRef, useMemo } from 'react';
import {
  getCurrentWeekDay,
  getWeekRange,
  getWorkingHoursForDate,
} from '../../utils/dateHelpers';
import './styles.scss';
import HoursList from './HoursList';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  getCalEvents,
  getWorkingHours,
  selectTrainer,
} from '../../features/calendar/calendarSlice';
import { Button } from '../Button';
import { Title } from '../Title';
import {
  ICalEvent,
  ITimeRange,
} from '../../features/calendar/calendarInterfaces';
import dayjs, { Dayjs } from 'dayjs';
import DatePicker from '../DatePicker';
import { STATUS_IDS } from '../../constants/globalConstants';
import DropDown from '../DropDown';
import { getUsers } from '../../features/account/accountSlice';
import { isTrainer } from '../../utils/accountHelpers';

export type CalendarProps = {
  date: Dayjs;
  onEmptyClick?: (time: ITimeRange) => void;
  onEventClick?: (event: ICalEvent) => void;
  onDayChange?: (date: Dayjs) => void;
};

const CalendarComponent = (props: CalendarProps) => {
  const dispatch = useAppDispatch();
  const workingHours = useAppSelector((state) => state.calendar.workingHours);
  const selectedTrainer = useAppSelector(
    (state) => state.calendar.selectedTrainer
  );
  const account = useAppSelector((state) => state.account);
  const strings = useAppSelector((state) => state.i18n.strings);
  const date = props.date;
  const [day, setDay] = useState(date.isoWeekday() - 1);
  const [week, setWeek] = useState(date.isoWeek());
  const [year, setYear] = useState(date.year());
  const [weekRange, setWeekRange] = useState(getWeekRange(week));
  const [showDatePicker, setShowDatepicker] = useState(false);
  // const { trainerId: selectedTrainer } = props;
  let wrapperRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  // the required distance between touchStart and touchEnd to be detected as a swipe
  const minSwipeDistance = 30;

  useEffect(() => {
    if (!selectedTrainer) {
      const trainerId = isTrainer(account.userData)
        ? account.userData.id
        : account.userData.trainerId;
      dispatch(selectTrainer(trainerId as string));
    }
  }, []);

  useEffect(() => {
    setWeekRange(getWeekRange(week, year));
  }, [week, year]);

  useEffect(() => {
    if (selectedTrainer) {
      dispatch(
        getCalEvents({
          ...weekRange,
          filters: {
            statusIds: [STATUS_IDS.active, STATUS_IDS.pending],
            trainer: selectedTrainer,
          },
          doNotUseCache: true,
        })
      );
      dispatch(
        getWorkingHours({
          ...weekRange,
          trainer: selectedTrainer,
          doNotUseCache: true,
        })
      );
    }
  }, [weekRange, selectedTrainer, dispatch]);

  const workingDays = useMemo<ITimeRange[]>(() => {
    let output: ITimeRange[] = [];
    let daysCount = dayjs
      .duration(weekRange.start.diff(weekRange.end))
      .asDays();
    daysCount = Math.min(Math.ceil(Math.abs(daysCount)), 7);
    for (let i = 0; i < daysCount; i++) {
      const day = weekRange.start.add(dayjs.duration({ days: i }));
      const workRange = getWorkingHoursForDate(day, workingHours);
      if (workRange) {
        output.push(workRange);
      }
    }
    return output;
  }, [workingHours, weekRange]);

  const trainers = useMemo<{ value: string; name: string }[]>(() => {
    if (!account.userList.length) {
      dispatch(getUsers());
      return [];
    }
    return account.userList
      .filter((user) => user.roles?.some((role) => role.name === 'TRAINER'))
      .map((user) => ({
        value: user.id as string,
        name: user.displayName as string,
      }));
  }, [account.userList]);

  const currentTrainer =
    trainers.find((t) => t.value === selectedTrainer) || trainers[0];

  const changeWeek = (modifier: number) => {
    let date = dayjs()
      .year(year)
      .isoWeek(week + modifier)
      .isoWeekday(day + 1);
    props.onDayChange?.(date);
  };

  const changeDay = (newDay: number) => {
    let date = dayjs()
      .year(year)
      .isoWeek(week)
      .isoWeekday(day + 1);
    let duration = dayjs.duration({ days: newDay });
    date = date.add(duration);
    props.onDayChange?.(date);
  };

  const handleDateClick = () => {
    setShowDatepicker(true);
  };

  useEffect(() => {
    handleDayPick(date);
  }, [date]);

  const handleDayPick = (newDate: Dayjs) => {
    const newWeek = newDate.isoWeek();
    const newDay = getCurrentWeekDay(newDate.isoWeekday());
    const newYear = newDate.year();
    setWeekRange(getWeekRange(newWeek, newYear));
    setYear(newYear);
    setWeek(newWeek);
    setDay(newDay);
    setShowDatepicker(false);
  };

  let touchEnd: number | null = null;
  let touchStart: number | null = null;
  const onTouchStart = (e: TouchEvent) => {
    touchEnd = null;
    touchStart = e.targetTouches[0].clientX;
  };

  const onTouchMove = (e: TouchEvent) => {
    touchEnd = null;
    touchEnd = e.targetTouches[0].clientX;
  };

  const onTouchEnd = (e: TouchEvent) => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe || isRightSwipe) {
      const modifier = isLeftSwipe ? 1 : -1;
      let changeFunction = changeDay;
      if (window.innerWidth > 500) {
        changeFunction = changeWeek;
      }
      changeFunction(modifier);
    }
  };

  if (workingDays.length < 7) {
    return null;
  }

  const longestDay = workingDays.reduce((longest, wd) => {
    const current = longest.end.diff(longest.start);
    const next = wd.end.diff(wd.start);
    if (current >= next) {
      return longest;
    }
    return wd;
  }, workingDays[0]);

  const currentDate = dayjs()
    .year(year)
    .isoWeek(week)
    .day(day + 1);

  return (
    <div className="calendar-wrapper" ref={wrapperRef}>
      <div className="calendar-controls">
        <Button
          className="calendar-controls-week"
          onClick={() => changeWeek(-1)}
        >
          {'<'}
        </Button>
        <Button
          className="calendar-controls-week"
          onClick={() => changeWeek(1)}
        >
          {'>'}
        </Button>
        <Title className="range-title calendar-controls-week" size="mid">
          {weekRange.start.format('DD MMMM')} -{' '}
          {weekRange.end.format('DD MMMM')}
        </Title>
        <Button className="calendar-controls-day" onClick={() => changeDay(-1)}>
          {'<'}
        </Button>
        <Title
          className="range-title day-picker-link calendar-controls-day"
          size="small"
          onClick={handleDateClick}
        >
          {workingDays[day].start.format('DD MMMM')}
        </Title>
        <Button className="calendar-controls-day" onClick={() => changeDay(1)}>
          {'>'}
        </Button>
        <div className="calendar-controls-trainer-selector">
          {trainers.length && (
            <DropDown
              items={trainers}
              kind="standard"
              defaultItem={currentTrainer}
              autoWidth={true}
              onChange={(item) => dispatch(selectTrainer(item.value))}
              placeholder={strings.role_trainer}
            ></DropDown>
          )}
        </div>
      </div>
      <div
        className="calendar-days-wrapper"
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
      >
        {workingDays.map((wd, index) => {
          const key = `day-${index}-${wd.start.format('DD-MM-YY')}`;
          return (
            <div
              key={key}
              className={`calendar-day-wrapper ${
                index === day ? 'current' : ''
              }`}
            >
              <HoursList
                range={longestDay}
                workingHours={wd}
                onEmptyClick={props.onEmptyClick}
                onEventClick={props.onEventClick}
              />
            </div>
          );
        })}
      </div>
      {showDatePicker && wrapperRef.current && (
        <DatePicker
          date={currentDate}
          onDateSelected={handleDayPick}
          onClose={() => setShowDatepicker(false)}
          anchor={wrapperRef.current}
        ></DatePicker>
      )}
    </div>
  );
};

export default CalendarComponent;
