import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Dayjs } from 'dayjs';
import axios from '../../utils/axios';
import { getWeekRange } from '../../utils/dateHelpers';
import CacheService from '../cache/cacheService';
import {
  ICalendarState,
  ICalEventResponse,
  IWorkingHoursData,
} from './calendarInterfaces';

const initialState: ICalendarState = {
  calEvents: [],
  workingHours: {},
  reportData: null,
  workingHoursStatus: 'idle',
  reportDataStatus: 'idle',
  calEventsStatus: 'idle',
  addCalEventStatus: 'idle',
  selectedTrainer: undefined,
};

export const calendarSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    clearReportData: (state) => {
      state.reportData = null;
    },
    selectTrainer: (state, action: PayloadAction<string>) => {
      state.selectedTrainer = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCalEvents.pending, (state) => {
        state.calEventsStatus = 'pending';
        state.calEvents = [];
      })
      .addCase(
        getCalEvents.fulfilled,
        (state, action: PayloadAction<ICalEventResponse[]>) => {
          state.calEventsStatus = 'idle';
          state.calEvents = action.payload || [];
        }
      )
      .addCase(getCalEvents.rejected, (state, action) => {
        state.calEventsStatus = 'failed';
      })
      .addCase(getWorkingHours.pending, (state) => {
        state.workingHoursStatus = 'pending';
        state.workingHours = {};
      })
      .addCase(
        getWorkingHours.fulfilled,
        (state, action: PayloadAction<IWorkingHoursData>) => {
          state.workingHoursStatus = 'idle';
          state.workingHours = action.payload;
        }
      )
      .addCase(getWorkingHours.rejected, (state, action) => {
        state.workingHoursStatus = 'failed';
      })
      .addCase(getCalReportData.pending, (state) => {
        state.reportDataStatus = 'pending';
      })
      .addCase(
        getCalReportData.fulfilled,
        (state, action: PayloadAction<ICalEventResponse[]>) => {
          state.reportData = action.payload;
          state.reportDataStatus = 'idle';
        }
      )
      .addCase(getCalReportData.rejected, (state, action) => {
        state.reportDataStatus = 'failed';
      })
      .addMatcher(
        (action) =>
          action.type &&
          (action.type as string).match(/^calendar\/eventAction\//),
        (state, action: PayloadAction<ICalEventResponse[]>) => {
          if (action.type.match(/.*\/pending/)) {
            state.addCalEventStatus = 'pending';
          }
          if (action.type.match(/.*\/fulfilled/)) {
            state.addCalEventStatus = 'idle';
          }
        }
      );
  },
});

const getCalEvents = createAsyncThunk(
  'calendar',
  async (
    reqData: {
      start: Dayjs;
      end: Dayjs;
      filters?: any;
      doNotUseCache?: boolean;
    },
    { rejectWithValue }
  ) => {
    try {
      const cacheService = CacheService.getInstance();
      const isoStart = reqData.start.toISOString();
      const isoEnd = reqData.end.toISOString();
      const cacheKey = `${isoStart}-${isoEnd}-${
        JSON.stringify(reqData.filters) || ''
      }`;
      const cacheData = cacheService.getCacheItem(cacheKey);
      if (cacheData && !reqData.doNotUseCache) {
        return Promise.resolve(cacheData);
      }
      const payload = {
        start: isoStart,
        end: isoEnd,
        filters: reqData.filters,
      };
      const path = '/calendar';
      let promise = cacheService.getCacheItem(path + cacheKey);
      if (!promise) {
        promise = axios.post(path, payload);
        cacheService.setCacheItem(path + cacheKey, promise);
      }
      const response = await promise;
      cacheService.evictCacheItem(path + cacheKey);
      cacheService.setCacheItem(cacheKey, response.data);
      return (response.data || []).sort(
        (a: ICalEventResponse, b: ICalEventResponse) => a.start > b.start
      );
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const getWorkingHours = createAsyncThunk(
  'workinghours',
  async (
    reqData: {
      start: Dayjs;
      end: Dayjs;
      trainer?: string;
      doNotUseCache?: boolean;
    },
    { rejectWithValue }
  ) => {
    try {
      const cacheService = CacheService.getInstance();
      const isoStart = reqData.start.toISOString();
      const isoEnd = reqData.end.toISOString();
      const cacheKey = `whours_${isoStart}-${isoEnd}-${reqData.trainer || ''}`;
      const cacheData = cacheService.getCacheItem(cacheKey);
      if (cacheData && !reqData.doNotUseCache) {
        return Promise.resolve(cacheData);
      }
      const payload = {
        start: isoStart,
        end: isoEnd,
        trainerId: reqData.trainer,
      };
      const path = '/calendar/workinghours';
      let promise = cacheService.getCacheItem(path + cacheKey);
      if (!promise) {
        promise = axios.post(path, payload);
        cacheService.setCacheItem(path + cacheKey, promise);
      }
      const response = await promise;
      cacheService.evictCacheItem(path + cacheKey);
      cacheService.setCacheItem(cacheKey, response.data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

type EventAction =
  | 'create'
  | 'reschedule'
  | 'approve'
  | 'reject'
  | 'cancel'
  | 'changeprice'
  | 'changenotification';

const executeEventAction = async (args: {
  action: EventAction;
  payload: any;
  evictCache?: { start: Dayjs };
}) => {
  if (args.evictCache) {
    const weekRange = getWeekRange(
      args.evictCache.start.isoWeek(),
      args.evictCache.start.year()
    );
    const isoStart = weekRange.start.toISOString();
    const isoEnd = weekRange.end.toISOString();
    const cacheKey = `${isoStart}-${isoEnd}`;
    CacheService.getInstance().evictCacheItem(cacheKey);
  }
  const reqPayload = { ...args.payload, action: args.action };
  return axios.post('/calendar/action', reqPayload);
};

const addCalEvent = createAsyncThunk(
  'calendar/eventAction',
  async (
    eventData: {
      disableNotifications: boolean;
      oneTimeDisableNotifications: boolean;
      forUser: string;
      start: Dayjs;
      end: Dayjs;
      price?: number;
      trainerId?: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const payload: {
        [key: string]: unknown;
        data: { [key: string]: unknown };
      } = {
        disableNotifications: eventData.disableNotifications || false,
        oneTimeDisableNotifications:
          eventData.oneTimeDisableNotifications || false,
        data: {
          forUser: eventData.forUser,
          start: eventData.start.toISOString(),
          end: eventData.end.toISOString(),
        },
      };
      if (eventData.price) {
        payload.data['price'] = eventData.price;
      }
      if (eventData.trainerId) {
        payload.data['trainerId'] = eventData.trainerId;
      }

      const response = await executeEventAction({
        action: 'create',
        payload,
        evictCache: eventData,
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const editCalEvent = createAsyncThunk(
  'calendar/eventAction',
  async (
    eventData: {
      eventId: number;
      forUser: string;
      oneTimeDisableNotifications: boolean;
      start: Dayjs;
      end: Dayjs;
      price?: number;
      trainerId?: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const payload: {
        [key: string]: unknown;
        data: { [key: string]: unknown };
      } = {
        eventId: eventData.eventId,
        oneTimeDisableNotifications:
          eventData.oneTimeDisableNotifications || false,
        data: {
          forUser: eventData.forUser,
          start: eventData.start.toISOString(),
          end: eventData.end.toISOString(),
        },
      };
      if (eventData.price) {
        payload.data['price'] = eventData.price;
      }
      if (eventData.trainerId) {
        payload.data['trainerId'] = eventData.trainerId;
      }

      const response = await executeEventAction({
        action: 'reschedule',
        payload: payload,
        evictCache: eventData,
      });

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const approveCalEvent = createAsyncThunk(
  'calendar/eventAction',
  async (
    eventData: {
      eventId: number;
      oneTimeDisableNotifications: boolean;
      start: Dayjs;
      price?: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const payload = {
        eventId: eventData.eventId,
        oneTimeDisableNotifications:
          eventData.oneTimeDisableNotifications || false,
      };
      if (eventData.price) {
        (payload as any)['price'] = eventData.price;
      }

      const response = await executeEventAction({
        action: 'approve',
        payload: payload,
        evictCache: eventData,
      });

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const rejectCalEvent = createAsyncThunk(
  'calendar/eventAction',
  async (
    eventData: {
      eventId: number;
      oneTimeDisableNotifications: boolean;
      start: Dayjs;
      price?: number;
      deleteOnReject?: boolean;
    },
    { rejectWithValue }
  ) => {
    try {
      const payload = {
        eventId: eventData.eventId,
        oneTimeDisableNotifications:
          eventData.oneTimeDisableNotifications || false,
        delete: eventData.deleteOnReject || false,
      };
      if (eventData.price) {
        (payload as any)['price'] = eventData.price;
      }

      const response = await executeEventAction({
        action: 'reject',
        payload: payload,
        evictCache: eventData,
      });

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const cancelCalEvent = createAsyncThunk(
  'calendar/eventAction',
  async (
    eventData: {
      eventId: number;
      oneTimeDisableNotifications: boolean;
      start: Dayjs;
      deleteOnCancel?: boolean;
    },
    { rejectWithValue }
  ) => {
    try {
      const payload = {
        eventId: eventData.eventId,
        oneTimeDisableNotifications:
          eventData.oneTimeDisableNotifications || false,
        delete: eventData.deleteOnCancel || false,
      };

      const response = await executeEventAction({
        action: 'cancel',
        payload: payload,
        evictCache: eventData,
      });

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const editCalEventPrice = createAsyncThunk(
  'calendar/eventAction',
  async (
    eventData: {
      eventId: number;
      start: Dayjs;
      price: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const payload = {
        eventId: eventData.eventId,
        price: eventData.price,
      };

      const response = await executeEventAction({
        action: 'changeprice',
        payload: payload,
        evictCache: eventData,
      });

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const editCalEventNotification = createAsyncThunk(
  'calendar/eventAction',
  async (
    eventData: {
      start: Dayjs;
      eventId: number;
      oneTimeDisableNotifications: boolean;
      disableNotifications: boolean;
    },
    { rejectWithValue }
  ) => {
    try {
      const payload = {
        eventId: eventData.eventId,
        disableNotifications: eventData.disableNotifications || false,
        oneTimeDisableNotifications:
          eventData.oneTimeDisableNotifications || false,
      };

      const response = await executeEventAction({
        action: 'changenotification',
        payload: payload,
        evictCache: eventData,
      });

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const getCalReportData = createAsyncThunk(
  'report',
  async (
    reqData: { start: Dayjs; end: Dayjs; filters?: any },
    { rejectWithValue }
  ) => {
    try {
      const isoStart = reqData.start.toISOString();
      const isoEnd = reqData.end.toISOString();
      const payload = {
        start: isoStart,
        end: isoEnd,
        filters: reqData.filters,
      };
      const path = '/calendar';
      const response = await axios.post(path, payload);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export {
  getCalEvents,
  getWorkingHours,
  addCalEvent,
  editCalEvent,
  editCalEventPrice,
  editCalEventNotification,
  approveCalEvent,
  rejectCalEvent,
  cancelCalEvent,
  getCalReportData,
};

export const { clearReportData, selectTrainer } = calendarSlice.actions;

export default calendarSlice.reducer;
