import axios from 'axios';
import { store } from '../app/store';
import { logout } from '../features/account/accountSlice';

const instance = axios.create({
  baseURL: process.env.REACT_APP_STAGE === 'prod' ? 'https://www.medaapa.eu/api':'https://hwz6wdwze2.execute-api.eu-west-1.amazonaws.com/latest/api',
  headers: {
    'content-type': 'application/json',  
  }
});

instance.interceptors.request.use(
  config => {
    if (config.headers) {
      config.headers["Authorization"] = localStorage.getItem('authToken') || "";
    }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
  response => response,
  error => {
    const {status} = error.response;
    if (status === 401) {
      store.dispatch(logout());
    }
    return Promise.reject(error);
 }
);

export default instance;