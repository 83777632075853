import dayjs, { Dayjs } from 'dayjs';
import duration from 'dayjs/plugin/duration';
import React, { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { STATUS_IDS } from '../../constants/globalConstants';
import { UserData } from '../../features/account/accountInterfaces';
import { getUser } from '../../features/account/accountSlice';
import {
  ICalEvent,
  ITimeRange,
} from '../../features/calendar/calendarInterfaces';
import {
  editCalEvent,
  editCalEventNotification,
  editCalEventPrice,
  getCalEvents,
  getWorkingHours,
} from '../../features/calendar/calendarSlice';
import { isAdmin, isTrainer } from '../../utils/accountHelpers';
import { getWeekRange } from '../../utils/dateHelpers';
import {
  validateConflicts,
  validateIsInWorkHours,
  validateIsUserActive,
  validatePriceNumber,
  Validator,
} from '../../utils/validations';
import CalendarEventForm, {
  CalendarFieldErrors,
  formFieldName,
} from '../CalendarEventForm';
import { useSearchParams } from 'react-router-dom';

dayjs.extend(duration);

export type CalendarEditItemProps = {
  onSuccess?: (range: ITimeRange) => void;
  item: ICalEvent;
};

const defaultItem: ICalEvent = {
  id: 0,
  status: { id: 0, name: '' },
  disableNotifications: false,
  start: dayjs(),
  end: dayjs(),
};

const CalendarEditItem = (props: CalendarEditItemProps) => {
  const [cachedItem] = useState(props.item || defaultItem); //This is a workaround. I'd rather we change the way we show/hide SlideInComponent (in Calendar and MyAppoitments) with the same variable we pass here for Item
  const item = props.item || cachedItem;
  const dispatch = useAppDispatch();
  const [, setSearchParams] = useSearchParams();
  const { calEvents, workingHours, selectedTrainer } = useAppSelector(
    (state) => state.calendar
  );
  const account = useAppSelector((state) => state.account);
  const strings = useAppSelector((state) => state.i18n.strings);
  const [eventStart, setEventStart] = useState(item.start);
  const [eventEnd, setEventEnd] = useState(item.end);
  const [eventForUser, setForUser] = useState<UserData | null>();
  const [eventDuration, setEventDuration] = useState(
    dayjs.duration(Math.abs(item.end.diff(item.start)))
  );
  const [eventPrice, setEventPrice] = useState(item.price?.toFixed(2) || '0');
  const [disableNotifications, setDisableNotifications] = useState(
    item.disableNotifications
  );
  const [oneTimeDisableNotifications, setOneTimeDisableNotifications] =
    useState(false);
  const [fieldErrors, setFieldsErrors] = useState<CalendarFieldErrors>({});
  const [formFields, setFields] = useState<{ [name: string]: any }>({});
  const fieldValidators: { [index: string]: Validator[] } = useMemo(
    () => ({
      start: [
        async (value: Dayjs) => {
          const range = getWeekRange(value.isoWeek(), value.year());
          setSearchParams({ date: value.format('YYYY-MM-DD') });
          const wh = await dispatch(
            getWorkingHours({
              start: range.start,
              end: range.end,
              trainer: selectedTrainer,
            })
          ).unwrap();
          return validateIsInWorkHours(value, wh, strings);
        },
        async (value: Dayjs) => {
          const range = getWeekRange(value.isoWeek(), value.year());
          await dispatch(
            getCalEvents({
              start: range.start,
              end: range.end,
              filters: {
                statusIds: [STATUS_IDS.active, STATUS_IDS.pending],
                trainer: selectedTrainer,
              },
            })
          );
          return validateConflicts(value, eventEnd, calEvents, strings, [
            item.id,
          ]);
        },
      ],
      end: [
        async (value: Dayjs) => {
          const range = getWeekRange(value.isoWeek(), value.year());
          setSearchParams({ date: value.format('YYYY-MM-DD') });
          const wh = await dispatch(
            getWorkingHours({
              start: range.start,
              end: range.end,
              trainer: selectedTrainer,
            })
          ).unwrap();
          return validateIsInWorkHours(value, wh, strings);
        },
        async (value: Dayjs) => {
          const range = getWeekRange(value.isoWeek(), value.year());
          await dispatch(
            getCalEvents({
              start: range.start,
              end: range.end,
              filters: {
                statusIds: [STATUS_IDS.active, STATUS_IDS.pending],
                trainer: selectedTrainer,
              },
            })
          );
          return validateConflicts(eventStart, value, calEvents, strings, [
            item.id,
          ]);
        },
      ],
      disableNotifications: [],
      duration: [],
      forUser: [(value) => validateIsUserActive(value, strings)],
      price: [(value) => validatePriceNumber(value, strings)],
    }),
    [eventStart, eventEnd, item, workingHours]
  );

  const onChangeHandler = (newFields: typeof formFields) => {
    setEventStart(newFields['start']);
    setDisableNotifications(newFields['disableNotifications']);
    setOneTimeDisableNotifications(newFields['oneTimeDisableNotifications']);
    setForUser(newFields['forUser'] || eventForUser);
    setEventDuration(newFields['duration'] || eventDuration);
    setEventPrice(newFields['price']);
  };

  const onSubmitHandler = async (data: { [name: string]: any }) => {
    const {
      disableNotifications,
      start,
      end,
      price,
      oneTimeDisableNotifications,
    } = data;
    const payload = {
      eventId: item.id,
      disableNotifications,
      oneTimeDisableNotifications,
      start,
      end,
      forUser: eventForUser?.id || '',
      price,
      trainerId: selectedTrainer || item?.trainer?.id,
    };
    if (
      !(start as Dayjs).isSame(item.start) ||
      !(end as Dayjs).isSame(item.end)
    ) {
      await dispatch(editCalEvent(payload));
    }
    if (payload.price) {
      payload.price = parseFloat(payload.price);
      if (payload.price !== item.price) {
        await dispatch(editCalEventPrice(payload));
      }
    }
    if (payload.disableNotifications !== item.disableNotifications) {
      await dispatch(editCalEventNotification(payload));
    }
    props.onSuccess?.(payload);
  };

  const validateField = async (
    fieldName: formFieldName,
    value: any,
    errorOutput: any
  ) => {
    const validators = fieldValidators[fieldName] || [];
    const validated = await Promise.all(
      validators.map((validator) => validator(value))
    );
    const error = validated.reduce((result, valid) => {
      if (result) {
        return result;
      }
      const validationResult = valid;
      if (!validationResult.isValid) {
        return (result = validationResult.errorMessage);
      }
      return result;
    }, '');
    if (error) {
      errorOutput[fieldName] = error;
    } else {
      delete errorOutput[fieldName];
    }
  };

  useEffect(() => {
    if (eventForUser && eventForUser.id === account.selectedUser?.id) {
      return;
    }
    if (item.forUser?.id) {
      dispatch(getUser({ userId: item.forUser?.id }));
    }
  }, []);

  useEffect(() => setForUser(account.selectedUser), [account.selectedUser]);

  useEffect(() => {
    setEventEnd(eventStart.add(eventDuration));
  }, [eventStart, eventDuration]);

  useEffect(() => {
    const output: { [name: string]: any } = {};
    const errorOutput: { [index: string]: string } = {};
    const isUserAdmin = isAdmin(account.userData);
    const isTrainerForEvent =
      isTrainer(account.userData) && item.trainer?.id === account.userData.id;
    output['start'] = eventStart;
    output['end'] = eventEnd;
    output['disableNotifications'] = disableNotifications;
    output['status'] = item.status.id;

    if (isUserAdmin || isTrainerForEvent) {
      output['forUser'] = eventForUser;
      output['duration'] = eventDuration;
      output['price'] = eventPrice.toString();
      output['oneTimeDisableNotifications'] = oneTimeDisableNotifications;
    }

    setFields(output);

    const validators = [
      validateField('start', eventStart, errorOutput),
      validateField('end', eventEnd, errorOutput),
      validateField('disableNotifications', disableNotifications, errorOutput),
    ];

    if (isUserAdmin || isTrainerForEvent) {
      validators.push(
        validateField('forUser', eventForUser, errorOutput),
        validateField('duration', eventDuration, errorOutput),
        validateField('price', eventPrice, errorOutput),
        validateField(
          'oneTimeDisableNotifications',
          oneTimeDisableNotifications,
          errorOutput
        )
      );
    }
    Promise.all(validators).then(() => {
      setFieldsErrors(errorOutput);
    });
  }, [
    eventStart,
    eventEnd,
    eventForUser,
    disableNotifications,
    oneTimeDisableNotifications,
    eventDuration,
    eventPrice,
  ]);

  return (
    <CalendarEventForm
      title={strings.editEvent}
      btnText={strings.update}
      fields={formFields}
      fieldErrors={fieldErrors}
      disabledFields={['forUser']}
      onChange={onChangeHandler}
      onSubmit={onSubmitHandler}
    />
  );
};

export default CalendarEditItem;
